<template>
    <div class="tabs">
        <div class="topSearch">
            <div class="search-item">
                设备名称： <el-input class="inputBox" size="small" v-model="pageParams.equipmentName"
                    placeholder="请输入"></el-input>
            </div>
            <div class="search-item">
                设备状态：
                <el-select class="selectBox" size="small" placeholder="请选择" v-model="pageParams.equipmentStatus">
                    <el-option v-for="item in equipmentStatus" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="search-item">
                站点分类：
                <el-select class="selectBox" size="small" placeholder="请选择" v-model="pageParams.stationType"
                    @change="stationTypeChange">
                    <el-option v-for="item in stationType" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="search-item">
                站点类型：
                <el-select class="selectBox" size="small" placeholder="请选择" v-model="pageParams.stationSecondType"
                    @change="stationSecondTypeChange">
                    <el-option v-for="item in stationTypeDict" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="search-item">
                所属站点：
                <el-select class="selectBox" size="small" placeholder="请选择" v-model="pageParams.stationId">
                    <el-option v-for="item in stationDict" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="searchBtn">
                <el-button type="primary" size="small" @click="search">查询</el-button>
                <el-button type="info" plain size="small" @click="reset">重置</el-button>
            </div>
        </div>
        <div class="operateBtn">
            <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="add()">添加</el-button>
            <el-button type="primary" size="small" plain @click="exportDefault">导出</el-button>
        </div>
        <el-table :data="tableData" border class="tableStyle" height="calc(100vh - 351px)">
            <el-table-column label="序号" type="index" width="52">
            </el-table-column>
            <el-table-column v-for="col in head" :key="col.prop" :prop="col.prop" :label="col.label" align="left"
                show-overflow-tooltip :sortable="col.sorts" :width="col.widths">
                <template v-if="col.isBar" #default="{ row }"></template>
            </el-table-column>
            <el-table-column label="操作" width="180">
                <template slot-scope="{ row }">
                    <span class="linkBtn" style="margin-right:16px" @click="add(row.id)">编辑</span>
                    <span class="linkBtn" style="margin-right:16px" @click="del(row.id)">删除</span>
                    <span class="linkBtn" @click="monitor(row)">监测点位</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="margin-top: 10px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageParams.current" :page-sizes="[30, 50, 100]" :page-size="pageParams.size"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <add ref="add"></add>
        <monitoring ref="monitoring"></monitoring>
        <bind ref="bind"></bind>
    </div>
</template>
  
<script>
import equipmentManageApi from '@/apis/equipmentManage/equipmentManageApi'
import { exportMethod } from '@/common/js/exportExcel'
import indexMixin from '@/mixins/index.js'
import { mapActions, mapState } from 'vuex'
import add from './components/add.vue'
import bind from './components/bind.vue'
import monitoring from './components/monitoring.vue'
export default {
    name: "pressure",
    components: { add, monitoring, bind },
    mixins: [indexMixin],
    data() {
        return {
            tableData: [],
            total: 0,
            pageParams: {
                size: 100,
                current: 1,
                equipmentName: ''
            },
            surePageParams: {},
            head: [
                { prop: 'equipmentName', label: '设备名称' },
                { prop: 'equipmentCode', label: '设备编码' },
                { prop: 'stationName', label: '所属站点' },
                { prop: 'soleCode', label: '设备唯一码', widths: '100' },
                { prop: 'spec', label: '规格' },
                { prop: 'mode', label: '型号' },
                { prop: 'factory', label: '生产厂家' },
                { prop: 'installDate', label: '安装日期' },
                { prop: 'transmitName', label: '是否远传' },
                { prop: 'equipmentStatusName', label: '设备状态' },
                { prop: 'remark', label: '备注' }
            ],
        };
    },
    computed: {
        ...mapState('dict', ['equipmentType', 'equipmentStatus', 'stationType', 'stationSecondType', 'stationId', 'stationDict'])
    },
    async mounted() {
        await this.getDropdown('equipmentType,equipmentStatus,stationType,stationSecondType,stationId')
        let { value, label } = this.stationType.find(item => { return item.label.includes('供气站') })
        this.$set(this.pageParams, 'stationType', value || 1)
        await this.isStringStartsWith1Or2(label)
        this.getSelectStationOptions()
        this.$nextTick(() => {
            this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
            this.getList(this.pageParams)
        })
    },
    methods: {
        ...mapActions('dict', ['getDropdown', 'selectStationOptions']),
        async getSelectStationOptions() {
            let { stationType, stationSecondType } = this.pageParams
            this.selectStationOptions({ stationType, stationSecondType })
        },
        async getList(pageParams) {
            let params = JSON.parse(JSON.stringify(pageParams))
            let { value } = this.equipmentType.find(item => { return item.label.includes('压力计') })
            params.equipmentType = value || 2
            equipmentManageApi.getList(params).then(res => {
                if (res.code == 200) {
                    this.tableData = res.data.list
                    this.total = res.data.total
                    if (res.data.total && !res.data.list.length) {
                        params.current = Math.ceil(res.data.total / params.size)
                        this.surePageParams = JSON.parse(JSON.stringify(params))
                        this.getList(params)
                    }
                } else this.$message.error(res.msg)
            })
        },
        async stationTypeChange(e) {
            let { value, label } = this.stationType.find(item => { return item.value == e })
            await this.isStringStartsWith1Or2(label)
            this.getSelectStationOptions()
            this.$set(this.pageParams, 'stationSecondType', null)
            this.$set(this.pageParams, 'stationId', null)
        },
        stationSecondTypeChange(e) {
            this.getSelectStationOptions()
            this.$set(this.pageParams, 'stationId', null)
        },
        add(id) {
            this.$refs.add.open(id, '压力计')
        },
        /**
         * Description 删除
         * @author slx
         * @date 2024-01-06
         * @param {any} id
         * @returns {any}
         */
        del(id) {
            this.$confirm('确认删除该设备信息及其监测点位信息？', '提示', {
                distinguishCancelAndClose: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                equipmentManageApi.deleteEquipment({ id }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.search()
                    } else this.$message.error(res.msg)
                })
            }).catch(action => { });
        },
        /**
         * Description  监测点位
         * @author slx
         * @date 2024-01-06
         * @param {any} id
         * @returns {any}
         */
        monitor(row) {
            let { id, equipmentName, stationId } = row
            this.$refs.monitoring.open(id, stationId, equipmentName, '压力计')
        },
        search() {
            // this.pageParams.current = 1
            this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
            this.getList(this.pageParams)
        },
        reset() {
            this.pageParams = {
                current: 1,
                size: 100,
                equipmentName: ''
            }
            let { value, label } = this.stationType.find(item => { return item.label.includes('供气站') })
            this.$set(this.pageParams, 'stationType', value || 1)
            this.$nextTick(() => {
                this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
                this.getList(this.pageParams)
            })
        },
        handleCurrentChange(e) {
            this.pageParams.current = e;
            this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
            this.getList(this.pageParams);
        },
        handleSizeChange(e) {
            this.pageParams.size = e;
            this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
            this.getList(this.pageParams);
        },
        exportDefault() {
            let params = JSON.parse(JSON.stringify(this.surePageParams))
            let { value } = this.equipmentType.find(item => { return item.label.includes('压力计') })
            params.equipmentType = value || 2
            params.current = 0
            params.size = -1
            equipmentManageApi.exportEquipment(params).then(res => {
                exportMethod(res, '压力装置')
            })
        },
    },
};
</script>
  
<style lang="scss" scoped>
.linkBtn {
    cursor: pointer;
    color: #1082FF;
}

.tabs {
    margin: 10px;
    background: #fff;
    padding: 16px 10px;
    height: calc(100vh - 205px);

    .topSearch {
        color: #0F0F0F;
        border-bottom: 1px solid #E3E3E3;
        padding: 0 10px 6px;
        display: flex;
        flex-wrap: wrap;

        .search-item {
            margin-bottom: 10px;
        }

        .inputBox {
            width: 200px;
            margin: 0 30px 0 4px;
        }

        .selectBox {
            width: 190px;
            margin: 0 30px 0 4px;
        }

        .searchBtn {
            display: inline-block;
        }
    }

    .operateBtn {
        margin: 16px 0 10px;
    }
}
</style>
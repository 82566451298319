<template>
    <el-dialog :visible.sync="dialogForms" :close-on-press-escape="false" :close-on-click-modal="false" top="10vh"
        width="470px" title="站点信息" :destroy-on-close="true" @close="close()">
        <template slot="title">
            <div class="dialogTitles"><span></span> <b>{{ id ? '编辑' : '添加' }}设备信息</b></div>
        </template>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" :inline="true" class="addForm">
            <el-row :gutter="30">
                <el-col :span="12">
                    <el-form-item label="设备名称" prop="equipmentName">
                        <el-input v-model="ruleForm.equipmentName" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所属站点" prop="stationId">
                        <el-select v-model="ruleForm.stationId" filterable placeholder="请选择">
                            <el-option v-for="item in stationDict" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="['阀门'].includes(typeName)">
                    <el-form-item label="阀门种类">
                        <el-select v-model="ruleForm.valveKind" placeholder="请选择">
                            <el-option v-for="item in valveKind" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="设备唯一码">
                        <el-input maxlength="100" v-model="ruleForm.soleCode" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="规格">
                        <el-input v-model="ruleForm.spec" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="型号">
                        <el-input v-model="ruleForm.mode" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="安装日期">
                        <el-date-picker v-model="ruleForm.installDate" type="date" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="['流量计', '压力计'].includes(typeName)">
                    <el-form-item label="是否远传" prop="isTransmit">
                        <el-select v-model="ruleForm.isTransmit" placeholder="请选择">
                            <el-option v-for="item in whether" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="设备状态" prop="equipmentStatus">
                        <el-select v-model="ruleForm.equipmentStatus" placeholder="请选择">
                            <el-option v-for="item in equipmentStatus" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="生产厂家">
                        <el-input v-model="ruleForm.factory" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注">
                        <el-input type="textarea" v-model="ruleForm.remark" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <!-- 按钮顺序 重置 取消 保存 -->
            <el-button size="medium" @click="close()">取消</el-button>
            <el-button type="primary" size="medium" @click="save">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import equipmentManageApi from '@/apis/equipmentManage/equipmentManageApi'
import indexMixin from '@/mixins/index.js'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
export default {
    mixins: [indexMixin],
    data() {
        return {
            id: null,
            typeName: '',
            dialogForms: false,
            info: '弹框信息',
            ruleForm: {},
            rules: {
                equipmentName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
                stationId: [{ required: true, message: '请选择所属站点', trigger: 'change' }],
                designScale: [
                    {
                        pattern: /^(?:\d{0,5}|0(?!(?:\.0{0,6})?$))?(?:\.\d{0,4})?$/,
                        message: '只能输入小数点前5位，后4位的数字',
                        trigger: 'blur'
                    }
                ],
                isTransmit: [{ required: true, message: '请选择是否远传', trigger: 'change' }],
                equipmentStatus: [{ required: true, message: '请选择设备状态', trigger: 'change' }],
            }

        }
    },
    computed: {
        ...mapState('dict', ['equipmentType', 'equipmentStatus', 'whether', 'valveKind', 'stationDict'])
    },
    created() {
        console.log('created');
    },
    mounted() { },
    methods: {
        ...mapActions('dict', ['getDropdown', 'selectStationOptions']),
        async open(id, typeName) {
            await this.getDropdown('equipmentType,equipmentStatus,whether,valveKind')
            await this.isStringStartsWith1Or2(typeName)
            await this.selectStationOptions()
            console.log(this.stationDict);
            this.id = id
            this.typeName = typeName
            let { value } = this.equipmentType.find(item => { return item.label.includes(typeName) })
            value = value || 1
            if (id) {
                equipmentManageApi.getEquipmentInfo({ id }).then(res => {
                    if (res.code == 200) {
                        this.ruleForm = {
                            ...this.ruleForm,
                            ...res.data
                        }
                    } else this.$message.error(res.msg)
                })
            } else {
                let { equipmentStatus } = this
                let index = equipmentStatus.findIndex(item => item.label.includes('启用'))
                this.ruleForm = {
                    ...this.ruleForm,
                    equipmentStatus: equipmentStatus.length ? equipmentStatus[index].value : null,
                    installDate: moment().format('YYYY-MM-DD'),
                    equipmentType: value
                }
                this.$forceUpdate()
            }
            this.dialogForms = true
        },
        close() {
            this.ruleForm = {}
            this.$refs['ruleForm'].resetFields()
            this.dialogForms = false
        },
        save() {
            this.$refs['ruleForm'].validate(valid => {
                if (valid) {
                    let params = JSON.parse(JSON.stringify(this.ruleForm))
                    params.equipmentType = params.equipmentType || this.equipmentType
                    if (this.id) {
                        if (params.point) params.point = params.point.split("(")[1].split(")").join(" ")
                        equipmentManageApi.updateEquipment(params).then(res => {
                            if (res.code == 200) {
                                this.$message.success(res.msg)
                                this.close()
                                this.$parent.search()
                            } else this.$message.error(res.msg)
                        })
                    } else {
                        equipmentManageApi.addEquipment(params).then(res => {
                            if (res.code == 200) {
                                this.$message.success(res.msg)
                                this.close()
                                this.$parent.search()
                            } else this.$message.error(res.msg)
                        })
                    }
                } else { }
            })
        },
    }
}
</script>

<style lang="scss" scoped></style>